import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-search-field',
  standalone: true,
  imports: [],
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.css',
})
export class SearchFieldComponent {
  @Output() valueChange = new EventEmitter<string>();
  @Input() placeholder: string = '';
  @Input() extraClasses: string = '';

  onValueChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.valueChange.emit(value.trim());
  }

}
